import type { TimeComponent } from "./types";

// get week number
// export toWeekNumber() {
// }

export type SettingsZ = {
	Z?: boolean;
};

export type Settings = SettingsZ & {
	add?: boolean;
	epoch?: number;
	YYYY?: string | number;
	MM?: string | number;
	DD?: string | number;
	T?: string | 0;
	HH?: string | number;
	mm?: string | number;
	ss?: string | number;
	sss?: string | number;
};

export const setNewDate = (opt: Settings = {}, D: Date = new Date()): Date => {
	if (Object.keys(opt).length === 0) return D;
	// Set by `epoch`
	if (typeof opt.epoch === "number" && !opt.add) D.setTime(opt.epoch);
	else if (typeof opt.epoch === "number") D.setTime(D.getTime() + opt.epoch);
	// Set by `YYYY`, `MM`, `DD`
	if (typeof opt.YYYY !== "undefined" && typeof +opt.YYYY === "number") {
		const y = opt.add ? D.getFullYear() + +opt.YYYY : +opt.YYYY;
		(() => opt.Z ? D.setUTCFullYear(y) : D.setFullYear(y))();
	}
	if (typeof opt.MM !== "undefined" && typeof +opt.MM === "number") {
		const m = opt.add ? D.getMonth() + +opt.MM - 1 : +opt.MM - 1;
		(() => opt.Z ? D.setUTCMonth(m) : D.setMonth(m))();
	}
	if (typeof opt.DD !== "undefined" && typeof +opt.DD === "number") {
		const d = opt.add ? D.getDate() + +opt.DD : +opt.DD;
		(() => opt.Z ? D.setUTCDate(d) : D.setDate(d))();
	}
	// Set by `T`, ISO time string
	if (typeof opt.T === "string" || opt.T === 0) {
		// Remove prepending "T" and appending timezone, if any
		const timeOnly = `${opt.T}`.replace(/T?(.*)(Z$|[-+].*)/g, "$1");
		const [hh, mm, sssss] = timeOnly.split(":");
		const [ss, sss] = (sssss || "").split(".");
		// Set hours, minute, second, millisecond
		(() => typeof hh === "string" && /^\d\d?$/.test(hh) ? opt.Z ?
			D.setUTCHours(+hh) : D.setHours(+hh) : D.setHours(0))();
		(() => typeof mm === "string" && /^\d\d?$/.test(mm) ? opt.Z ?
			D.setUTCMinutes(+mm) : D.setMinutes(+mm) : D.setMinutes(0))();
		(() => typeof ss === "string" && /^\d\d?$/.test(ss) ? opt.Z ?
			D.setUTCSeconds(+ss) : D.setSeconds(+ss) : D.setSeconds(0))();
		(() => typeof sss === "string" && /^\d\d?\d?$/.test(sss) ? opt.Z ?
			D.setUTCMilliseconds(+sss) : D.setMilliseconds(+sss) : D.setMilliseconds(0))();
	}
	// Set hours, minutes, seconds, milliseconds
	if (typeof opt.HH !== "undefined" && /^\d\d?$/.test(String(opt.HH))) {
		const h = opt.add ? D.getHours() + +opt.HH : +opt.HH;
		(() => opt.Z ? D.setUTCHours(h) : D.setHours(h))();
	}
	if (typeof opt.mm !== "undefined" && /^\d\d?$/.test(String(opt.mm))) {
		const m = opt.add ? D.getMinutes() + +opt.mm : +opt.mm;
		(() => opt.Z ? D.setUTCMinutes(m) : D.setMinutes(m))();
	}
	if (typeof opt.ss !== "undefined" && typeof (+opt.ss) === "number") {
		const s = opt.add ? D.getSeconds() + +opt.ss : +opt.ss;
		(() => opt.Z ? D.setUTCSeconds(s) : D.setSeconds(s))();
	}
	if (typeof opt.sss !== "undefined" && typeof (+opt.sss) === "number") {
		const ms = opt.add ? D.getMilliseconds() + +opt.sss : +opt.sss;
		(() => opt.Z ? D.setUTCMilliseconds(ms) : D.setMilliseconds(ms))();
	}

	return D;
};

export const dateGet = (init?: TimeComponent | { UTC?: boolean, component?: TimeComponent; }, D = new Date()): string => {
	const { component: c, UTC: Z } = typeof init === "object" ? init : { component: init, UTC: false };

	if (/^year|YYYY$/.test(`${c || ""}`))
		return String(Z ? D.getUTCFullYear() : D.getFullYear()).padStart(4, "0");
	if (/^month|MM$/.test(`${c || ""}`))
		return String((Z ? D.getUTCMonth() : D.getMonth()) + 1).padStart(2, "0");
	if (/^day|DD$/.test(`${c || ""}`))
		return String(Z ? D.getUTCDate() : D.getDate()).padStart(2, "0");
	if (/^hour|HH$/.test(`${c || ""}`))
		return String(Z ? D.getUTCHours() : D.getHours()).padStart(2, "0");
	if (/^minute|mm$/.test(`${c || ""}`))
		return String(Z ? D.getUTCMinutes() : D.getMinutes()).padStart(2, "0");
	if (/^second|ss$/.test(`${c || ""}`))
		return String(Z ? D.getUTCSeconds() : D.getSeconds()).padStart(2, "0");
	if (/^millisecond|sss$/.test(`${c || ""}`))
		return String(Z ? D.getUTCMilliseconds() : D.getMilliseconds()).padStart(3, "0");
	else // if "epoch" or undefined
		return String(D.getTime());
};

export const dateGetUTC = (c?: TimeComponent, D = new Date()): string =>
	dateGet({ component: c, UTC: true }, D);
