/*
 * theTime – v0.5.3 – 2025-03-27
 * Exports `theTime` and `theUTCTime` factory functions.
 * @license ISC
 * @author J. Sebergsen Steinberg
 */

import { setNewDate, dateGet, type Settings, type SettingsZ } from "./functions";
import type { TimeDateComponent, TimeComponent } from "./types";
type DateInit = Date | TimeDate | Settings | string;
type Factory = (init?: Date | TimeDate | Settings | string, whereOptions?: Settings) => TimeDate;
export type { TimeDate };

// Pattern for last day of month:
// theTime(date).goes({ MM: 1 }).where({ DD: 0 });

/**
 * new Date object of extended TimeDate object.
 *
 * - Defaults to current time.
 * - If `t` is not set (excluding `add` time), local time will be used,
 *   else if `t` is set, even empty string, unset time components will default to 0.
 * - Option `MM` to set Date start from 1, not native 0, others are intuitive as original.
 *
 * @param init: string | Date | TimeDate | Settings – Date object, date time string, or set options.
 * @param whereOptions: Settings -- set options set init when init isn't options.
 * @returns TimeDate – extended Date object. */
export const theTime: Factory = (init, whereOptions) => {
	const settings = findSettings(init, whereOptions);

	return (settings.Z
		? theUTCTime(init, whereOptions)
		: setNewDate(settings, newTimeDate(theTime, init))
	) as TimeDate;
};

export default theTime;

/* Like Time, but with UTC time. */
export const theUTCTime: Factory = (init, whereOptions) =>
	setNewDate(
		{ Z: true, ...findSettings(init, whereOptions), },
		newUTCTimeDate(theUTCTime, init)
	) as TimeDate;

// TimeDate object from eg YYYYMM, YYYYMMDD, YYYYMMDDHHmm, YYYYMMDDHHmmss
export const theTimeFrom = (
	val: string | number,
	form: string,
	opt: Settings = {},
): TimeDate => theTimeFromComponentString(val, form, opt);

export const theUTCTimeFrom = (val: string | number, form: string, opt: Settings = {}): TimeDate =>
	theTimeFromComponentString(val, form, opt, theUTCTime);

class TimeDate extends Date {
	private _new: Factory;
	private _Z?: boolean;

	constructor(opt: SettingsZ & { next: Factory, init?: string | Date | TimeDate; }) {
		if (opt.init)
			super(opt.init);
		else
			super();

		this._new = opt.next;
		this._Z = opt.Z || false;
	}

	// Set
	where(c: Settings | TimeDateComponent = {}, value?: string | number) {
		return this._new(this, typeof c === "string" ? { [c]: value } : c);
	}

	// Set as addition/subtraction
	goes(c: Settings | TimeDateComponent = {}, value?: string | number) {
		return this._new(this, {
			...(typeof c === "string" ? { [c]: value } : c), add: true
		});
	}

	// Set by dayOfWeek @returns TimeDate object in this' week
	whereDayOfWeek(weekday: 1 | 2 | 3 | 4 | 5 | 6 | 7) {
		return this._new(this).goes({ DD: weekday - (this.getDay() || 7) });
	}

	// Get time component string
	get(c?: TimeComponent, options?: SettingsZ): string {
		return dateGet({ component: c, UTC: options?.Z || this._Z }, this);
	}

	// Get time components string[]
	list(c: TimeComponent | TimeComponent[], opt?: SettingsZ): string[] {
		return (Array.isArray(c) ? c : [c])
			.reduce((list, c) => [...list, this.get(c, opt)], [] as string[]);
	}

	// Get joined time components string
	to(c: TimeComponent | TimeComponent[], y?: string | SettingsZ, z?: SettingsZ): string {
		return this.list(c, z ? z : typeof y === "object" ? y : {}).join(typeof y === "string" ? y : "");
	}

	// Get plain date string
	toPlainDateString(as: SettingsZ = {}): string {
		return this.to(["YYYY", "MM", "DD"], "-", as);
	}
}

// @returns TimeDate object from an ISO date string
const newTimeFromDateTimeString = (dT: string, factory: Factory): TimeDate => {
	// If is date time string with timezone, it can be used to create a Date object
	if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d{3})?)?(Z|[-+]\d{2}:\d{2})$/.test(dT))
		return new TimeDate({ init: dT, next: factory });

	const [y, m, d] = dT.split("-");
	const timeString = dT.split("T")[1];
	const as = (org: string = "1", to: number) => org.padStart(to, "0").substring(0, to);

	return factory({ YYYY: as(y, 4), MM: as(m, 2), DD: as(d, 2), T: timeString || 0 }) as TimeDate;
};

// @returns object from init, string, or a new TimeDate object
const newTimeDate = (factory: Factory, init?: DateInit): TimeDate =>
	init instanceof Date || init instanceof TimeDate
		? new TimeDate({ init, next: factory }) : typeof init === "string"
			? newTimeFromDateTimeString(init, factory)
			: new TimeDate({ next: factory });

const newUTCTimeDate = (factory: Factory, init?: DateInit): TimeDate =>
	init instanceof Date || init instanceof TimeDate
		? new TimeDate({ init, Z: true, next: factory }) : typeof init === "string"
			? newTimeFromDateTimeString(init, factory)
			: new TimeDate({ Z: true, next: factory });

const findSettings = (
	init?: DateInit,
	addOptions?: Settings
): Settings =>
	init instanceof Object && !(init instanceof Date) && !(init instanceof TimeDate)
		? init : addOptions || {};

// TimeDate object from eg YYYYMM, YYYYMMDD, YYYYMMDDHHmm, YYYYMMDDHHmmss
const theTimeFromComponentString = (
	val: string | number,
	form: string,
	opt: Settings = {},
	factory = theTime
): TimeDate => {
	const compsStr = String(val).padStart(form.length, "0");
	const compsMap = form
		.replace(/[^YMDHms]/g, "_")
		.split("")
		.reduce((acc: Record<string, number[]>, c, i) => {
			if (c === "s" && (acc["s"] || []).length > 1)
				acc["sss"] = [...(acc["sss"] || []), i];
			else if (c !== "_")
				acc[c] = [...(acc[c] || []), i];
			return acc;
		}, {});
	const comps = Object.keys(compsMap)
		.reduce((acc: Record<string, string>, c) => {
			acc[c === "sss" ? c : compsMap[c].map(() => c).join("")]
				= compsStr.substring(compsMap[c][0], compsMap[c][compsMap[c].length - 1] + 1);
			return acc;
		}, {});

	return factory({ ...comps, T: 0 }).where(opt);
};
